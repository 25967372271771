<template>
  <div id="login"  >
    <div class="columns is-mobile is-centered m-5 ">
      <div class="column is-half is-primary ">
           Dashboard
      </div><!-- Login class -->
    </div>
    </div>
</template>

<script>

export default {
  name: 'Dashboard',
  data(){
    return{
    }
  }
}
</script>

